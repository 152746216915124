import React from 'react'
import Layout from '../layouts/default'
import SEO from '../components/seo'
import Landing from '@components/Landing'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Bassel`, `BasselAhmed`, `React`, `Vue`, `Front-end`, `Engineer`, `Developer`]} />
    <Landing />
  </Layout>
)

export default IndexPage
